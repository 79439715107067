import logo from '../layout/assets/imgs/pago_special_v3.png';

function Logo() {
  return (
    <>
      <img src={logo} alt="PAGO Logo" className="logo noselect" />
    </>
  );
}

export default Logo;
